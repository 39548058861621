/* COLORS */
:root {
  --header-bg-color: #ffffff;
  --content-bg-color: #ffffff;
  --footer-bg-color: #ffffff;
  --error-color: #fb2667;
  --input-error-color: #fdbcbc;
  --finish-bg-color: #0ec14a;
  --finish-color: #ffffff;
  --advance-bg-color: #57becd;
  --advance-color: #ffffff;
  --main-font-color: #78849e;
  --main-color: #2699fb;
  --line-bg-color: #e8e8e8;

  --card-font-color: #ffffff;

  --form-button-border-color: #bce0fd;

  --frequency-bg-color: #ffffff;
  --frequency-bottom-bg-color: #bbc1cd;
  --frequency-bottom-font-color: #ffffff;

  --payment-change-mobile-font-color: #ffffff;

  --card-icon-color: #2699fb;
  --card-icon-visa-electron: #2699fb;
  --card-icon-master-card-color-1: #2e88f3;
  --card-icon-master-card-color-2: #2699fb;
  --card-icon-master-card-color-3: #4fadfe;
  --card-icon-elo: #2699fb;
  --card-icon-visa: #2699fb;
  --card-icon-amex-color-1: #2699fb;
  --card-icon-amex-color-2: #ffffff;
}

#checkout-propago {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  margin: 0;
  width: 100%;
  height: 100%;
}

.hidden {
  visibility: hidden;
}