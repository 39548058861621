.app, .app-content, .app-header, .app-footer {
    display: flex;
    width: 100%;
}

.app {
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    background-color: var(--content-bg-color);
    font-family: "Open Sans", sans-serif;
}

.app-content {
    flex: 1;
}

/* loading */
.loading__title {
    color: var(--main-font-color);
    font-size: 35px;
    font-weight: bold;
    text-align: center;
}

.loading__subtitle {
    padding: 0 15px;
    color: var(--main-font-color);
    font-size: 25px;
    font-weight: 400;
    text-align: center;
}

.loading__spinner {
    color: var(--advance-bg-color);
    width: 4rem;
    height: 4rem;
    font-weight: bold;
}

/* i18n button */
.wrapper-button-i18n {
    z-index: 2;
}

/* Media queries */
@media (min-width: 1440px) {
    .row-checkout .app-header .header, .row-checkout .app-footer {
        padding: auto 18%;
    }
    .row-checkout .app-content {
        padding: auto 10%;
    }
}

@media (min-width: 1080px) {
    .row-checkout .app-header .header, .row-checkout .app-footer {
        padding: auto 10%;
    }
    .row-checkout .app-content {
        padding: auto 5%;
    }
}

@media (max-width: 575px) {
    .wrapper-button-i18n {
        left: 15px;
        top: 58px;
    }
}

@media (min-width: 576px) {
    .wrapper-button-i18n {
        left: 15px;
        top: 18px;
    }
}